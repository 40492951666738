const getDesignTokens = () => ({
  typography: {
    fontFamily: "Sans-Serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sans-Serif';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "#FFCC00",
            },
            "& .Mui-focused fieldset": {
              borderColor: "#FFCC00",
            },
            "& .MuiOutlinedInput-input": {
              color: "white",
            },
            "& .MuiSvgIcon-root": {
              color: "white !important",
            },
            "& .Mui-disabled": {
              color: "white", // change the color of disabled text
              WebkitTextFillColor: "white",
            },
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .Mui-disabled": {
            color: "white", // change the color of disabled text
            WebkitTextFillColor: "white",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiButton: { styleOverrides: { root: { color: "white" } } },
    MuiSvgIcon: { styleOverrides: { root: { color: "#FFCC00" } } },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#313338", // Change background color of the calendar
          color: "white",
          "& .MuiSvgIcon-root": {
            color: "white !important",
          },
          "& .MuiTypography-root": {
            color: "white !important",
          },
          "& .MuiButtonBase-root": {
            color: "white !important",
          },
          "& .MuiPickersDay-today": {
            borderColor: "white !important",
          },
        },

        // day: {
        //   color: "blue", // Change color of the day numbers
        // },
        // daySelected: {
        //   backgroundColor: "blue", // Change background color of the selected day
        //   "&:hover": {
        //     backgroundColor: "red", // Change background color of the selected day when hovered
        //   },
        // },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "& .MuiMenuItem-root": {
            color: "white !important",
          },
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          "& .MuiClockNumber-root": {
            color: "white",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .MuiTypography-root": {
            color: "white !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "white",
          "& .Mui-disabled": {
            WebkitTextFillColor: "white",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: "white !important",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFCC00",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#FFCC00",
    },
    secondary: {
      main: "#FDB813",
    },
    button: {
      primary: {
        main: "#FFCC00",
      },
      secondary: {
        main: "#FDB813",
      },
      danger: {
        main: "#AE0000",
      },
      faded: {
        main: "#d8d8d8",
      }
    },
    background: {
      paper: "#313338",
      secondaryPaper: "#1B1C1E"
    },
  },
});

export default getDesignTokens;
