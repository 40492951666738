import React from "react";
import { Search } from "@mui/icons-material";
import { TextField, IconButton, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchBar = ({ setSearchQuery }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <form>
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        label={t("game.search")}
        variant="outlined"
        placeholder={t("game.search")}
        size="small"
      />
      <IconButton disabled>
        <Search style={{ fill: theme.palette.primary.main }} />
      </IconButton>
    </form>
  );
};

export default SearchBar;
