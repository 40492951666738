import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ResponsibleGaming = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.responsiblegaming.1.title")}
        </Typography>
        {/* <Typography variant="body1" component="div" fontWeight={800}>
          {t("tutorial.join.title")}
        </Typography> */}
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.1.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.1.seconddesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.1.thirddesc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.responsiblegaming.2.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.2.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.2.seconddesc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.responsiblegaming.3.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.3.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.3.list.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.4")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.5")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.6")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.7")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.8")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.3.list.9")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.3.seconddesc")}
        </Typography>

        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.responsiblegaming.4.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.4.firstdesc")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.4.seconddesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.responsiblegaming.4.list.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.4.list.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.4.list.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.4.list.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.responsiblegaming.4.list.4")}
            </li>
          </ol>
        </Typography>
      </Box>
    </Container>
  );
};

export default ResponsibleGaming;
