export const ALT_TAG_LOGO = "brand-logo";
export const ALT_TAG_BANNER = "banner";
export const ALT_TAG_JACKPOT = "jackpot";
export const ALT_TAG_STEPS = "steps";
export const ALT_TAG_ICON_SLOT = "icon-slot";
export const ALT_TAG_ICON_CASINO = "icon-casino";
export const ALT_TAG_ICON_SPORT = "icon-sport";
export const ALT_TAG_ICON_FISHING = "icon-fishing";
export const ALT_TAG_ICON_ESPORT = "icon-esport";
export const ALT_TAG_ICON_POKER = "icon-poker";
export const ALT_TAG_ICON_VIP = "icon-vip";
export const ALT_TAG_ICON_FACEBOOK = "icon-facebook";
export const ALT_TAG_ICON_WHATSAPP = "icon-whatsapp";
export const ALT_TAG_ICON_TELEGRAM = "icon-telegram";
export const ALT_TAG_ICON_PROMO = "icon-promo";
export const ALT_TAG_ADS_AFFL = "ads-affiliate";
export const ALT_TAG_ADS_PROMO = "ads-promo";
export const ALT_TAG_HOME_ADS = "home-ads";
export const ALT_TAG_HOME_HOT_GAME = "home-hot-game";
export const ALT_TAG_GENERAL_GAME = "game-";
export const ALT_TAG_GENERAL_PROVIDER = "provider-";
export const ALT_TAG_FOOTER_LICENSE = "Gaming Curacao";
export const ALT_TAG_FOOTER_CERT = {
    0: "bmm testlabs",
    1: "tst globals",
    2: "pagcor",
    3: "reco cert",
    4: "itech"
};

export const ALT_TAG_FOOTER_PAYMENT = {
    0: "zinpay",
    1: "quickpay",
    2: "gcash"
};
export const ALT_TAG_FOOTER_SECURITY = {
    0: "iovation"
};
export const ALT_TAG_FOOTER_RESP_GAMING = {
    0:"responsible gaming",
    1:"responsible gaming",
    2:"responsible gaming",
};
export const ALT_TAG_CATEGORY_BANNER = "category-banner-";
export const ALT_TAG_BONUS_IMG = "bonus-image";
export const ALT_TAG_VIP = "vip-";
export const ALT_TAG_AFFL_IMG = "affiliate-image";
export const ALT_TAG_ICON_DEPOSIT = "icon-deposit";
export const ALT_TAG_ICON_WITHDRAW = "icon-withdraw";
export const ALT_TAG_ICON_AFFL = "icon-affiliate";
export const COUNTRY_LANGUAGE_PATH_PARAMS = {
    MY: { en: "en-my", my: "ms-my" }
};

export const getPath = (path) => {
    if (process.env.REACT_APP_LANG_PATH_PARAM) {
        let lang = localStorage.getItem("language") || "en";
        if (!path.startsWith("/")) {
            path = "/" + path;
        }
        lang = COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][lang];
        return "/" + lang + path;
    }
    else {
        return path;
    }
}

export const getCustomMenuItems = () => {
    if (process.env.REACT_APP_COUNTRY == "MY") {
        return [
            { name: 'Slot',code: 'SLOT',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/SLOT.png', imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/SLOT_2.png' },
            { name: 'Live Casino', code: 'LIVE_CASINO',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/LIVE_CASINO.png',imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/LIVE_CASINO_2.png' },
            { name: 'Crash', code: 'CRASH',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/INSTANTWIN.png', imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/INSTANTWIN_2.png' },
            { name: 'Sport', code: 'SPORTBOOK',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/SPORT.png', imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/SPORT_2.png' },
            { name: 'Fishing', code: 'FISH_HUNTER',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/FISHING.png', imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/FISHING_2.png' },
            { name: 'Esports', code: 'ESPORTS',image: 'https://rich31.com:888/game_my_live/mobile_home_logo/ESPORTS.png', imageLogoOnly: 'https://rich31.com:888/game_my_live/mobile_home_logo/ESPORTS_2.png' },
        ];
    }
}