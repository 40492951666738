import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import {
  Campaign as CampaignIcon,
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import Marquee from "react-fast-marquee";
import usePagination from "../../hooks/usePagination";

const PER_PAGE = 2;

const Announcement = ({
  announcementDetails,
  pauseOnClick,
  pauseOnHover,
  gradientColor,
  speed,
  gradientWidth,
  direction,
  style,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({
    selected: false,
    selectedData: {},
  });
  const [page, setPage] = useState(1);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const count = Math.ceil(announcementDetails.length / PER_PAGE);
  const selectedData = usePagination(announcementDetails, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    selectedData.jump(p);
  };

  const filteredText = announcementDetails.map((item) => {
    return (
      <Typography color={"white"}>
        {item + ` ${"\xa0".repeat(mobileView ? 15 : tabletView ? 50 : 70)}`}
      </Typography>
    );
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handlePageChange(1, 1);
    handleSelectedAnnouncement({
      selected: false,
      selectedData: {},
    });
    setOpen(false);
  };

  const handleSelectedAnnouncement = (object) => {
    setSelectedAnnouncement(object);
  };

  // temporary placing modals here, will refactor if have time
  const readAllModal = ({ open, handleClose }) => {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: mobileView ? 300 : 400,
            backgroundColor: theme.palette.background.paper,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="600">
              Announcement
            </Typography>
            <IconButton edge="start" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedAnnouncement.selected === false ? (
            selectedData?.currentData().map((item, index) => (
              <Box key={index} display="flex" mt={2} flexDirection="column">
                {/* <Typography py={1} fontWeight="600">
                  {item?.title}
                </Typography> */}
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                  color={"black"}
                >
                  {item}
                </Typography>
                <Box my={2}>
                  <Button
                    disableElevation
                    disableRipple
                    sx={{
                      pl: 0,
                      color: theme.palette.primary.main,
                      "&.MuiButtonBase-root:hover": {
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                      },
                    }}
                    onClick={() =>
                      handleSelectedAnnouncement({
                        selected: true,
                        selectedData: item,
                      })
                    }
                  >
                    READ MORE
                  </Button>
                </Box>
                <Divider />
              </Box>
            ))
          ) : (
            <Box display="flex" mt={2} flexDirection="column">
              <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton
                  edge="start"
                  onClick={() =>
                    handleSelectedAnnouncement({
                      selected: false,
                      selectedData: {},
                    })
                  }
                >
                  <ArrowBackIcon />
                </IconButton>
                {/* <Typography py={1}>
                  {selectedAnnouncement?.selectedData?.date}
                </Typography> */}
              </Box>
              {/* <Typography py={1} fontWeight="600">
                {selectedAnnouncement?.selectedData?.title}
              </Typography> */}
              <Typography py={1} color={"black"}>
                {selectedAnnouncement?.selectedData}
              </Typography>
            </Box>
          )}
          <Stack mt={2} spacing={2} size="small" alignItems="center">
            <Pagination
              disabled={selectedAnnouncement.selected ? true : false}
              onChange={handlePageChange}
              count={count}
              shape="rounded"
            />
          </Stack>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        sx={{
          mt: 0,
          bgcolor: theme.palette.primary.main,
        }}
      >
        <CampaignIcon />
        <Marquee
          style={{
            width: mobileView ? "70%" : "82%",
            ...style,
          }}
          pauseOnClick={pauseOnClick}
          pauseOnHover={pauseOnHover}
          gradientColor={gradientColor}
          speed={speed}
          gradientWidth={gradientWidth}
          direction={direction}
        >
          {filteredText}
        </Marquee>
        <Button
          disableElevation
          disableRipple
          sx={{
            textAlign: "right",
            color: "white",
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "transparent",
              color: theme.palette.secondary.main,
            },
          }}
          onClick={handleOpen}
        >
          Read All
        </Button>
        {readAllModal({ announcementDetails, open, handleClose })}
      </Box>
    </>
  );
};

Announcement.propTypes = {
  announcementDetails: PropTypes.array,
  pauseOnClick: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  gradientColor: PropTypes.array,
  speed: PropTypes.number,
  gradientWidth: PropTypes.string,
  direction: PropTypes.string,
  style: PropTypes.object,
};

Announcement.defaultProps = {
  announcementDetails: [],
  pauseOnHover: true,
  pauseOnClick: true,
  gradientColor: [0, 0, 0],
  speed: 30,
  gradientWidth: "0px",
  direction: "left",
  style: {},
};

export default Announcement;
