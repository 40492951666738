import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Image from "./common/Image";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetGameCategoryQuery,
  useGetGameProviderQuery,
} from "../features/api/gameApiSlice";
import * as Constant from "../features/constant";

const certificationIcon = [
  process.env.REACT_APP_FOOTER + "/certificate1.png",
  process.env.REACT_APP_FOOTER + "/certificate2.png",
  process.env.REACT_APP_FOOTER + "/certificate3.png",
  process.env.REACT_APP_FOOTER + "/certificate4.png",
  process.env.REACT_APP_FOOTER + "/certificate5.png",
];

const paymentIcon = [
  process.env.REACT_APP_FOOTER + "/payment1.png",
  process.env.REACT_APP_FOOTER + "/payment2.png",
  process.env.REACT_APP_FOOTER + "/payment3.png",
  process.env.REACT_APP_FOOTER + "/payment4.png",
  process.env.REACT_APP_FOOTER + "/payment5.png",
  process.env.REACT_APP_FOOTER + "/payment6.png",
  process.env.REACT_APP_FOOTER + "/payment7.png",
];

const securityIcon = [process.env.REACT_APP_FOOTER + "/security1.png"];

const responsibleGamingIcon = [
  process.env.REACT_APP_FOOTER + "/responsible1.png",
  process.env.REACT_APP_FOOTER + "/responsible2.png",
  process.env.REACT_APP_FOOTER + "/responsible3.png",
];

const Footer = ({ background, contactDetails }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    data: gameCategory,
    isLoading: isGameCategoryLoading,
    isSuccess: isGameCategorySuccess,
  } = useGetGameCategoryQuery();

  const {
    data: gameProvider,
    isLoading: isGameProviderLoading,
    isSuccess: isGameProviderSuccess,
  } = useGetGameProviderQuery({ category: gameCategory?.result[0]?.code });

  const links = [
    i18n.t("footer.home"),
    i18n.t("footer.aboutus"),
    i18n.t("footer.game"),
    i18n.t("footer.bettingrules"),
    i18n.t("footer.t&c"),
    i18n.t("footer.promotion"),
    i18n.t("footer.faq"),
  ];

  return (
    <Box
      sx={{
        background: `url('${background}')`,
      }}
    >
      <Grid
        container
        // columns={4}
        direction={mobileView ? "column" : "row"}
        wrap="nowrap"
        p={0}
        px={{ sm: "100px", md: "300px" }}
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {links.map((item, index) => {
          return (
            <Grid
              item
              xs
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: 14,
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (item === t("footer.home")) {
                    navigate(Constant.getPath("/"));
                  } else if (item === t("footer.game")) {
                    navigate(
                      Constant.getPath(`/game/${gameCategory?.result[0].code?.toLowerCase().replaceAll("_", "-")}`),
                      {
                        state: {
                          providerId: gameProvider?.result[0]?.id,
                          bannerUrl: gameCategory?.result[0].bannerUrl,
                        },
                      }
                    );
                  } else if (item === t("footer.promotion")) {
                    navigate(Constant.getPath("/promotion"));
                  } else if (item === t("footer.aboutus")) {
                    navigate(Constant.getPath("/about"));
                  } else if (item === t("footer.bettingrules")) {
                    navigate(Constant.getPath("/rules"));
                  } else if (item === t("footer.t&c")) {
                    navigate(Constant.getPath("/terms"));
                  } else if (item === t("footer.faq")) {
                    navigate(Constant.getPath("/faq"));
                  }
                  window.scroll(0, 0);
                }}
              >
                {item}
              </Typography>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        py={2}
        px={tabletView ? 10 : 20}
      >
        {/* Gaming License */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.gaminglicense")}
            </Typography>
            <Image src={process.env.REACT_APP_FOOTER + "/certificate6.png"} alt={Constant.ALT_TAG_FOOTER_LICENSE}/>
            <Typography
              sx={{
                mt: 2,
                color: "white",
                fontSize: 12,
              }}
            >
              {t("footer.gaminglicensedesc")}
            </Typography>
          </Box>
        </Grid>
        {/* Certification */}
        <Grid item lg={5}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.certification")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {certificationIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon} alt={Constant.ALT_TAG_FOOTER_CERT[index]}/>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.paymentmethod")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {paymentIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon} alt={Constant.ALT_TAG_FOOTER_PAYMENT[index]} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        {/* Security */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "400px" }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.security")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {securityIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon}  alt={Constant.ALT_TAG_FOOTER_SECURITY[index]}/>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.responsiblegaming")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {responsibleGamingIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon}  alt={Constant.ALT_TAG_FOOTER_RESP_GAMING[index]}/>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  background: PropTypes.string,
  contactDetails: PropTypes.object,
};

Footer.defaultProps = {
  background: "",
  contactDetails: {},
};

export default Footer;
