import React from "react";
import {
  Card as MuiCard,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import Image from "../common/Image";

const GameCard = ({
  imageUrl,
  imageHeight,
  imageWidth,
  onClick,
  title,
  subtitle,
  description,
  isHotGame,
  alt
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <MuiCard
      variant="outlined"
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        borderRadius: 3,
        border: 0,
        height: isHotGame
          ? "220px"
          : mobileView
          ? "200px"
          : tabletView
          ? "210px"
          : "195px",
        width: imageWidth,
      }}
      onClick={onClick}
    >
      <Box
        overflow="hidden"
        sx={{ borderRadius: 3 }}
        height={imageHeight}
        width={imageWidth}
      >
        <Image
          src={imageUrl}
          sx={{
            position: "relative",
            top: 0,
          }}
          hovered={true}
          alt={alt}
        />
      </Box>
      {title && subtitle && (
        <>
          {mobileView || tabletView ? (
            <Box
              display="flex"
              flexDirection="column"
              p={1}
              justifyContent="center"
            >
              <Typography
                fontWeight={800}
                color={"white"}
                fontSize={12}
                textAlign="center"
              >
                {title}
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                fontSize={10}
                textAlign="center"
              >
                {subtitle}
              </Typography>
              {/* {description} */}
            </Box>
          ) : (
            <Box display="flex" pt={1} justifyContent="center">
              <Box display="flex" flexDirection="column">
                <Typography
                  fontWeight={800}
                  color={"white"}
                  fontSize={12}
                  textAlign="center"
                >
                  {title}
                </Typography>
                <Typography
                  color={theme.palette.primary.main}
                  fontSize={10}
                  textAlign="center"
                >
                  {subtitle}
                </Typography>
              </Box>
              {/* {description} */}
            </Box>
          )}
        </>
      )}
    </MuiCard>
  );
};

export default GameCard;
