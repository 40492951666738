import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useTheme,
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  Grid,
  Container,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import FormTextField from "../components/form/FormTextField";
import Backdrop from "../components/common/Backdrop";
import { useLoginMutation } from "../features/api/authApiSlice";
import { setCredentials } from "../features/slices/authSlice";
import { selectCurrentToken } from "../features/slices/authSlice";
import { setAlert } from "../features/slices/alertSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import ImageCarousel from "../components/common/ImageCarousel";
import * as Constant from "../features/constant";

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const lang = localStorage.getItem("language");

  const bonusSliderImage_en = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_en.png",
  ];

  const bonusSliderImage_bd = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_aud.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_aud.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_aud.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_aud.png",
  ];

  const loginSchema = yup.object().shape({
    username: yup.string().required(t("validation.username")),
    password: yup.string().required(t("validation.password")),
  });

  // utilize the other hooks to display loader, display error message or anything
  const [login, { data, isLoading, isError, isSuccess, error, reset }] =
    useLoginMutation();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (token) {
      navigate(Constant.getPath("/"));
    }
  }, []);

  const submitForm = async (data) => {
    let formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("grant_type", "password");

    const userData = await login(formData).unwrap();
    dispatch(setCredentials({ ...userData }));
  };

  if (!isLoading && isSuccess) {
    dispatch(
      setAlert({
        isOpen: true,
        message: t("header.loginsuccessmessage"),
        variant: "success",
      })
    );
    navigate(Constant.getPath("/"));
    reset();
  }

  if (!isLoading && isError) {
    dispatch(
      setAlert({
        isOpen: true,
        message: error.data.error_description,
        variant: "error",
      })
    );
    reset();
  }

  return (
    <Box backgroundColor="black" height="100vh">
      <Box p={2} backgroundColor="black">
        <Grid container justifyContent="space-between">
          <Image
            width="178px"
            src={process.env.REACT_APP_LOGO}
            height="50px"
            onClick={null}
          />
          <IconButton
            onClick={() => {
              navigate("/");
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Box>
      <Container>
        <Box mt={2}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: '#FFCC00',
            }}
            borderRadius={3}
          >
            <Box
              p={2}
              flexGrow={1}
              sx={{ cursor: "pointer", position: "relative" }}
              onClick={() => navigate(Constant.getPath("/login"))}
            >
              <Typography
                color='#050606'
                fontSize={14}
                fontWeight={800}
                align="center"
              >
                {t("mobileloginregister.login")}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderBottom: "2px solid",
                  width: "20%",
                  borderColor: "black",
                }}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              p={2}
              flexGrow={1}
              sx={{ cursor: "pointer", position: "relative" }}
              onClick={() => navigate(Constant.getPath("/register"))}
            >
              <Typography
                fontSize={14}
                fontWeight={800}
                align="center"
                color='#050606'
              >
                {t("mobileloginregister.signup")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <ImageCarousel
              banners={lang === 'en' ? bonusSliderImage_en : bonusSliderImage_bd}
              navigation={false}
              spaceBetween={0}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ backgroundColor: theme.palette.background.paper }}
            justifyContent="center"
            pb={3}
            px={5}
          >
            <form onSubmit={handleSubmit(submitForm)}>
              <FormTextField
                label={t("mobileloginregister.username")}
                control={control}
                controllerName="username"
                size="small"
              />
              <FormTextField
                label={t("mobileloginregister.password")}
                control={control}
                controllerName="password"
                type="password"
                size="small"
              />
              <Box
                display="flex"
                justifyContent="center"
                mt={isError ? "10px" : "30px"}
              >
                <Button variant="contained" type="submit" color="secondary" style={{ color: 'black' }}>
                  {t("mobileloginregister.login")}
                </Button>
              </Box>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <a
                    style={{color: theme.palette.primary.main}}
                    href="/forgotPassword"
                >
                  <Typography mt={2} fontSize={14}>
                    {t("mobileloginregister.forgotpassword")}
                  </Typography>
                </a>
              </div>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                <Typography mt={2} fontSize={14} style={{ color: 'white' }}>
                  {t("Any issues? Please contact our ")}
                  <a
                      style={{ color: theme.palette.primary.main }}
                      href="/ContactUs"
                  >
                    customer service
                  </a>
                  {"."}
                </Typography>
              </div>

            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
