import { apiSlice } from "./apiSlice";

export const gameApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "GameCategory",
    "GameItem",
    "GameProvider",
    "GameLaunch",
    "BetHistory",
  ],
  endpoints: (builder) => ({
    getGameCategory: builder.query({
      query: () => "/game/api/v1/gameCategory/list",
      providesTags: ["GameCategory"],
    }),
    getGameItem: builder.query({
      query: ({ category, offset, providerId, group, keyword }) =>
        `/game/api/v1/gameItem/list?category=${category}&offset=${offset}&providerId=${providerId}&group=${group}&keyword=${keyword}`,
      providesTags: ["GameItem"],
    }),
    getGameProvider: builder.query({
      query: ({ category, isHome }) =>
        `/game/api/v1/gameProvider/list?category=${category}&isHomepage=${isHome}`,
      providesTags: ["GameProvider"],
    }),
    getLaunchGame: builder.query({
      query: ({ id, type, category }) =>
        `/game/api/v1/launchGame?id=${id}&type=${type}&category=${category}`,
      providesTags: ["GameLaunch"],
    }),
    listBetHistory: builder.mutation({
      query: (queryBody) => ({
        url: "/game/api/v1/betHistoryGrouped/list",
        method: "POST",
        body: queryBody,
      }),
      providesTags: ["BetHistory"],
    }),
  }),
});

export const {
  useGetGameCategoryQuery,
  useLazyGetGameItemQuery,
  useGetGameProviderQuery,
  useLazyGetGameProviderQuery,
  useLazyGetLaunchGameQuery,
  useListBetHistoryMutation,
} = gameApiSlice;
