import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers";

const FormDatePicker = ({ control, time, label, value, size }) => {
  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "15px",
      }}
    >
      <Controller
        control={control}
        name="date"
        defaultValue={value || new Date()}
        render={({
          field: { ref, onBlur, name, ...field },
          fieldState: { error },
        }) => (
          <>
            {time ? (
              <DateTimePicker
                {...field}
                inputRef={ref}
                label={label}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    onBlur={onBlur}
                    name={name}
                    error={!!error}
                    helperText={error ? error.message : null}
                    size={size}
                  />
                )}
              />
            ) : (
              <DatePicker
                {...field}
                inputRef={ref}
                label={label}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    onBlur={onBlur}
                    name={name}
                    error={!!error}
                    helperText={error ? error.message : null}
                    size={size}
                  />
                )}
              />
            )}
          </>
        )}
      />
    </FormControl>
  );
};

FormDatePicker.propTypes = {};

FormDatePicker.defaultProps = {};

export default FormDatePicker;
