import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const KYCVerification = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.1.title")}
        </Typography>
        {/* <Typography variant="body1" component="div" fontWeight={800}>
          {t("tutorial.join.title")}
        </Typography> */}
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.1.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.1.list.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.1.list.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.1.list.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.1.list.3")}
            </li>
          </ol>
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.1.seconddesc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.2.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.2.list1.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.4")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.2.list2.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list2.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list2.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list2.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list2.4")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list2.5")}
            </li>
          </ol>
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.3.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.3.list1.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.3.list1.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.3.list1.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.3.list1.3")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.3.list2.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.2.list1.4")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.4.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.4")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.5")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.5.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.5.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.6.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.6.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.7.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.7.desc")}
        </Typography>
      </Box>
    </Container>
  );
};

export default KYCVerification;
