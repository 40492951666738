import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Message,
  CardGiftcard,
  Home,
  LocalAtm,
  Outbox,
  AccountBox
} from "@mui/icons-material";
import {
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectCurrentToken } from "../features/slices/authSlice";
import { selectAlertIsOpen } from "../features/slices/alertSlice";

import { useGetCommonBackgroundQuery } from "../features/api/publicApiSlice";

import DesktopHeader from "./Header/DesktopHeader";
import MobileHeader from "./Header/MobileHeader";
import Footer from "./Footer";
import SideNav from "./common/SideNav";
import SnackbarAlert from "./common/SnackbarAlert";
import * as Constant from "../features/constant";

const Layout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const alertIsOpen = useSelector(selectAlertIsOpen);
  
  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  const triggerLiveChat = () => {
    if (
      typeof window.LiveChatWidget !== "undefined" &&
      window.LiveChatWidget.call
    ) {
      window.LiveChatWidget.call("maximize");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="#1E1F22"
      minHeight="100vh"
    >
      {alertIsOpen && <SnackbarAlert />}
      {<SideNav />}
      <header>
        {mobileView ? (
          <>
            {!location.pathname.includes("/register") &&
              !location.pathname.includes("/login") &&
              !location.pathname.includes("/game/slot") &&
              !location.pathname.includes("/game/live-casino") &&
              !location.pathname.includes("/game/fish-hunter") &&
              !location.pathname.includes("/game/sportbook") &&
              !location.pathname.includes("/promotion") &&
              !location.pathname.includes("/forgotPassword") &&
              !location.pathname.includes("/resetPassword") &&
              !location.pathname.includes("/contactus") &&
              !location.pathname.includes("/language") &&
              !location.pathname.includes("/about") &&
              !location.pathname.includes("/terms") && (
                <MobileHeader
                  background={
                    commonBackground?.mobileBackground[0]?.thumbnailUrl
                  }
                />
              )}
          </>
        ) : (
          <DesktopHeader
            background={commonBackground?.webBackground[0]?.thumbnailUrl}
          />
        )}
      </header>
      <Box flex={1}>
        <Outlet />
      </Box>
      {!mobileView && (
        <footer>
          <Footer
            background={commonBackground?.webBackground[1]?.thumbnailUrl}
            contactDetails={commonBackground?.result}
          />
        </footer>
      )}
      
      {mobileView &&
        !location.pathname.includes("/register") &&
        !location.pathname.includes("/login") &&
        !location.pathname.includes("/game/slot") &&
        !location.pathname.includes("/game/live-casino") &&
        !location.pathname.includes("/game/fish-hunter") &&
        !location.pathname.includes("/game/sportbook") &&
        !location.pathname.includes("/forgotPassword") &&
        !location.pathname.includes("/resetPassword") &&
        !location.pathname.includes("/contactus") &&
        !location.pathname.includes("/language") &&
        !location.pathname.includes("/about") &&
        !location.pathname.includes("/terms") && (
          <AppBar
            position="fixed"
            style={{
              top: "auto",
              bottom: 0,
            }}
          >
            <BottomNavigation
              sx={{
                backgroundColor: '#FFCC00',
              }}
              showLabels
              // value={value}
              onChange={(event, newValue) => {
                if (newValue === 0) {
                  navigate(Constant.getPath("/"));
                } else if (newValue === 1) {
                  navigate(Constant.getPath("/promotion"));
                } else if (newValue === 2) {
                  if (token) {
                    navigate(Constant.getPath("/profileWallet"), {
                      state: {
                        screen: "Deposit",
                      },
                    });
                  } else {
                    navigate(Constant.getPath("/login"));
                  }
                } else if (newValue === 3) {
                  if (token) {
                    navigate(Constant.getPath("/profileWallet"), {
                      state: {
                        screen: "Withdrawal",
                      },
                    });
                  } else {
                    navigate(Constant.getPath("/login"));
                  }
                }else if(newValue === 4){
                  if (token) {
                    navigate(Constant.getPath("/profileWallet"))
                  } else {
                    navigate(Constant.getPath("/login"));
                  }
                } /*else if (newValue === 4) {
                  triggerLiveChat();
                }*/
              }}
            >
              <BottomNavigationAction
                sx={{ color: "black", minWidth: "0px" }}
                label={t("footer.home")}
                icon={<Home sx={{ color: "black" }}/>}
              />
              <BottomNavigationAction
                  sx={{ color: "black", minWidth: "0px" }}
                label={t("footer.promotion")}
                icon={<CardGiftcard sx={{ color: "black" }}/>}
              />
              <BottomNavigationAction
                  sx={{ color: "black", minWidth: "0px" }}
                label={t("footer.deposit")}
                icon={<LocalAtm sx={{ color: "black" }}/>}
              />
              <BottomNavigationAction
                  sx={{ color: "black", minWidth: "0px" }}
                label={t("footer.withdrawal")}
                icon={<Outbox sx={{ color: "black" }}/>}
              />
{/*              <BottomNavigationAction
                sx={{ color: "black" }}
                label={t("footer.livechat")}
                icon={<Message sx={{ color: "black" }}/>}
              />*/}
              <BottomNavigationAction
                  sx={{ color: "black", minWidth: "0px" }}
                  label={t("footer.account")}
                  icon={<AccountBox sx={{ color: "black" }}/>}
              />
            </BottomNavigation>
          </AppBar>
        )}
    </Box>
  );
};

export default Layout;
