import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  Box,
  Divider,
  List,
  ListItemButton,
  IconButton,
  useTheme,
  Typography,
  ListItemText,
} from "@mui/material";
import { Menu, Language, Call, Diversity3 } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../icons/header/HomeIcon";
import PromotionIcon from "../../icons/header/PromotionIcon";
import VipIcon from "../../icons/header/VipIcon";
import Image from "./Image";
import UserCard from "./UserCard";
import * as Constant from "../../features/constant";

const drawerWidth = 220;

const Drawer = ({ user, window }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const drawerItems = [
    {
      label: t("drawer.home"),
      code: "home",
      icon: (
        <HomeIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    },
    {
      label: t("drawer.promotion"),
      code: "promotion",
      icon: (
        <PromotionIcon
          width={25}
          height={25}
          color={theme.palette.primary.main}
        />
      ),
    },
    {
      label: t("drawer.vip"),
      code: "vip",
      icon: (
        <VipIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    },
    // {
    //   label: t("drawer.language"),
    //   code: "language",
    //   icon: (
    //     <Language
    //       sx={{
    //         color: theme.palette.primary.main,
    //       }}
    //     />
    //   ),
    // },
    // {
    //   label: t("drawer.contactus"),
    //   code: "contactus",
    //   icon: (
    //     <Call
    //       sx={{
    //         color: theme.palette.primary.main,
    //       }}
    //     />
    //   ),
    // },
    // {
    //   label: t("drawer.affiliate"),
    //   code: "affiliate",
    //   icon: (
    //     <Diversity3
    //       sx={{
    //         color: theme.palette.primary.main,
    //       }}
    //     />
    //   ),
    // },
    // {
    //   label: "Affiliate",
    //   icon: <Diversity3 sx={{ display: "block", width: "100%", mb: 1 }} />,
    // },
  ];
  Constant.getCustomMenuItems().map(item => {
    drawerItems.push({
      isGameCategory: true,
      label: t('gamecategory.' + item.code.toLowerCase().replaceAll("_", "").replaceAll("-", "")),
      code: item.code.replaceAll("_", "-"),
      icon: (
        <img src={item.imageLogoOnly} alt={item.name} style={{ width: '25px', height: '25px' }} />
      ),
    });
  });
  drawerItems.push({
    label: t("drawer.affiliate"),
    code: "affiliate",
    icon: (
      <Diversity3
        sx={{
          color: theme.palette.primary.main,
        }}
      />
    ),
  });
  drawerItems.push({
    label: t("drawer.contactus"),
    code: "contactus",
    icon: (
      <Call
        sx={{
          color: theme.palette.primary.main,
        }}
      />
    ),
  });
  drawerItems.push({
    label: t("drawer.language"),
    code: "language",
    icon: (
      <Language
        sx={{
          color: theme.palette.primary.main,
        }}
      />
    ),
  });

  const handleDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  // const handleGameDrawerToggle = () => {
  //   setGameDrawerOpen(!gameDrawerOpen);
  // };

  const drawerContent = (
    <>
      <Box>
        <UserCard
          isDrawer={true}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          user={user}
        />
      </Box>
      <Divider
        sx={{ bgcolor: theme.palette.primary.main, height: "5px", mb: 2 }}
      />
      <List sx={{ justifyContent: "center", alignSelf: "center" }}>
        {drawerItems.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={(event) => {
              const basePath = item?.isGameCategory ? "/game/" : "/";
              const path = item?.code?.toLowerCase() === "home"
                ? Constant.getPath("/")
                : Constant.getPath(basePath + item?.code?.toLowerCase().replace(/\s+/g, ""));
      
              navigate(path);
              handleDrawerToggle(event);
            }}
            sx={{ width: "100%", mb: 0 }}
            disableRipple
          >
            <>
              {item.icon}
              <Typography
                display="block"
                variant="sublabel1"
                textAlign="center"
                ml={3}
                lineHeight={0}
                fontWeight={600}
                fontSize={16}
                color="white"
              >
                {item.label}
              </Typography>
            </>
          </ListItemButton>
        ))}
      </List>
    </>
  );

  return (
    <>
      <IconButton
          sx={{ color: 'yellow' }}
        edge="start"
        onClick={handleDrawerToggle}
      >
        <Menu />
      </IconButton>
      <MuiDrawer
        container={container}
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: theme.palette.background.paper,
          },
        }}
      >
        {drawerContent}
      </MuiDrawer>
    </>
  );
};

Drawer.propTypes = {
  user: PropTypes.object,
};

Drawer.defaultProps = {
  user: {},
};

export default Drawer;
