import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  darken,
} from "@mui/material";
import { useGetProfileQuery } from "../../features/api/userApiSlice";
import { Forward, Refresh } from "@mui/icons-material";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import { apiSlice } from "../../features/api/apiSlice";
import * as Constant from "../../features/constant";

const PATH = process.env.PUBLIC_URL;

const WalletCard = ({ screens }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // borderRadius: 4,
        // border: 1,
        p: 2,
        backgroundColor: darken(theme.palette.background.paper, 0.1),
      }}
    >
      <Box>
        <Box sx={{ display: "flex", pb: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pr: 2,
            }}
          >
            <Box
              component="img"
              width="20px"
              height="20px"
              src={PATH + "/assets/img/wallet/wallet_card/Bell.png"}
            />
            <Box display="flex">
              <Typography fontSize={14} color="white">
                {t("walletcard.walletbalance")}
              </Typography>
              <Typography fontSize={14} color={theme.palette.secondary.main}>
                {` (${process.env.REACT_APP_CURRENCY_CODE})`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize={14} color="white">
                {user?.result?.currentCredit}
              </Typography>
              <IconButton
                onClick={() =>
                  dispatch(apiSlice.util.invalidateTags(["Profile"]))
                }
              >
                <Refresh style={{ color: "#29A829" }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              pl={2}
              sx={{ display: "flex", alignItems: "flex-end" }}
              onClick={() => {
                navigate(Constant.getPath("/profileWallet"), {
                  state: { screen: "Transaction" },
                });
              }}
            >
              <Typography mr={1} fontSize={14} color="white">
                {t("walletcard.transactionhistory")}
              </Typography>
              <Forward style={{ color: "#29A829" }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Grid container pt={1.5} spacing={1}>
        {screens?.map((screen) => (
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              p: 0,
            }}
            onClick={() => {
              if (
                screen?.label?.toLowerCase() === "promotion" ||
                screen?.label?.toLowerCase() === "vip"
              ) {
                navigate(Constant.getPath("/" + screen?.label));
              } else {
                navigate(Constant.getPath("/profileWallet"), {
                  state: { screen: screen?.code },
                });
              }
            }}
          >
            {screen?.icon}
            <Typography textAlign="center" fontSize={12} color="white">
              {screen?.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WalletCard;
